import axios from 'axios';
import { useAuthStore } from '../stores/authStore';

// 创建 Axios 实例
const axiosInstance = axios.create({
  baseURL: 'http://47.94.22.47:9099', // 替换为你的 API 端点
  timeout: 10000, // 请求超时时间
});

// 请求拦截器
axiosInstance.interceptors.request.use(
	config => {
		console.log(config);
		const authStore = useAuthStore();
		config.headers['Content-Type'] = 'application/json';
		// if (authStore.token) {
		// 	config.headers.Authorization = `Bearer ${authStore.token}`;
		// }
		return config;
	},
	error => Promise.reject(error)
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 处理未授权错误
          break;
        case 403:
          // 处理禁止访问错误
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
