<template>
	<div class="root">
		<h1>欢迎进入山海云数据建模系统</h1>
		<p>请选择您的项目，或新建项目开始建模。</p>
	</div>
	<div class="select">
		<select name="projects" id="projects">
			<option value="proj01">AI新媒体平台研发一期</option>
			<option value="proj02">元宇宙一期概念设计</option>
			<option value="proj03">影视博物馆项目</option>
			<option value="proj04">数字媒体研发项目</option>
			<option value="proj05">数字人虚拟现实研发项目</option>
		</select>
		<button class="bluesearchBtn" @click="openDialog(1)" >选择项目</button>
		<button class="bluesearchBtn" @click="openDialog(2)" >新建项目</button>
	</div>
	<div class="flow">
		<img src="../assets/images/flow.jpg"/>
	</div>
	
	<!-- 对话框 -->
	<dialog ref="dialog">
		<p>{{ talk }}，请确认您的权限，有疑问请与系统管理员联系！</p>
		<button @click="closeDialog" class="dialogBtn">确定</button>
	</dialog>
</template>

<script>
	export default {
		name: 'RootPage',
		data() {
			return {
				talk: "无权选择该项目",
			}
		},
		methods: {
			openDialog(i) {
				if (i === 1) {
					this.talk = "无权选择该项目";
				} else {
					this.talk = "无权新建项目"
				}
				this.$refs.dialog.showModal();
			},
			closeDialog() {
				this.$refs.dialog.close();
			},
		},
	};
</script>

<style>
	.root h1{
		font-size: 40px;
	}
	.root p{
		font-size: 32px;
	}
	.select {
		width: 100%;
		height: 100px;
		display: flex;
	}
	.flow {
		display: flex;
		width: 100%;
	}
	.flow img {
		min-width: 896px; /* 设置最小宽度 */
		min-height: 504px; /* 设置最小高度 */
		width: auto;
		height: auto;
	}
	#projects {
		margin-left: 20px;
		margin-bottom: 10px;
		width: 400px;
		float: left;
		height: 50px;
		font-size: 18px;
		font-weight: bold;
		text-align: left;
	}
	.bluesearchBtn {
		border-radius: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		display: flex;
		width: 120px;
		height: 50px;
		margin-left: 20px;
		/* float: left; */
		line-height: 50px;
		background: #2a80b8;
		font-size: 20px;
		color: #fff;
		border: none;
		cursor: pointer;
		box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
	}
	
	.bluesearchBtn:hover {
		color: #fea900;
	}
	
	.bluesearchBtn:active {
		transform: scale(0.98);
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
	}
	
	/* 对话框样式 */
	dialog {
		height: 140px;
		border: none;
		padding: 20px;
		border-radius: 8px;
		background-color: #fff;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	}
	
	dialog::backdrop {
		background-color: rgba(0, 0, 0, 0.3);
	}
	dialog p {
		font-size: 20px;
	}
	.dialogBtn {
		margin-top: 20px;
		margin-left: 40%;
		padding: 10px 20px;
		border: none;
		background-color: #007bff;
		color: #fff;
		border-radius: 4px;
		cursor: pointer;
		font-size: 20px;
		float: left;
	}
	
	.dialogBtn:hover {
		background-color: #0056b3;
	}
</style>