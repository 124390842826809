<template>
	<div class="main-top">
		<Header></Header>
		<ScrollPic></ScrollPic>
		<BigData></BigData>
		<Bottom></Bottom>
	</div>
</template>

<script>
	import Header from '@/components/MainHeader'
	import ScrollPic from '@/components/HomeScrollPic'
	import BigData from '@/components/MainBigData'
	import Bottom from '@/components/MainBottom'

	export default {
		name: 'App',
		components: {
			Header,
			ScrollPic,
			BigData,
			Bottom
		}
	}
</script>

<style>
</style>