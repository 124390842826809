<template>
<div class="homeScrollPic">
    <div class="box" @mouseout="out" @mouseover="over">
      <img
        v-for="(item, index) in list"
        v-show="listIndex === index"
        :key="index"
        :src="item"
        alt=""
      />
      <p class="left" @click="changePage(prevIndex)">&lt;</p>
      <ul>
        <li
          :class="{ color: index == listIndex }"
          v-for="(item, index) in list"
          @click="changePage(index)"
          :key="index"
        ></li>
      </ul>
      <p class="right" @click="changePage(nextIndex)">&gt;</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homeScrollPic',
  components: {},
  props: {},
  data() {
    return {
      list: [
        require("@/assets/images/ad01.png"),
        require("@/assets/images/ad02.png"),
        require("@/assets/images/ad03.png"),
      ],
      listIndex: 0, //默认显示第几张图片
      timer: null, //定时器
    };
  },
  computed: {
    //上一张
    prevIndex() {
      if (this.listIndex == 0) {
        return this.list.length - 1;
      } else {
        return this.listIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.listIndex == this.list.length - 1) {
        return 0;
      } else {
        return this.listIndex + 1;
      }
    },
  },
  methods: {
    
    changePage(index) {
      this.listIndex = index;
    },
    //移除
    out() {
      this.setTimer();
    },
    //移入
    over() {
      clearInterval(this.timer);
    },
    //3秒切图
    setTimer() {
      this.timer = setInterval(() => {
        this.listIndex++;
        if (this.listIndex == this.list.length) {
          this.listIndex = 0;
        }
      }, 3000);
    },
  },
  created() {
    //定时器
    this.setTimer();
  },
  mounted() {},
}
</script>

<style scoped lang="less">
.homeScrollPic {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 80px;
  .box {
    // position: relative;
    width: 100%;
    height: 100%;
    img {
		min-width: 1280px; /* 设置最小宽度 */
		min-height: 360px; /* 设置最小高度 */
		width: 100%;
		height: 100%;
		z-index: 100;
    }
    p {
      cursor: pointer;
      color: white;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5);
    }
    .left {
      position: absolute;
      top: 240px;
      left: 0;
    }
    .right {
      position: absolute;
      top: 240px;
      right: 0;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      width: 150px;
      height: 20px;
      // top: 40%;
      left: 40%;
      .color {
        background: red;
        color: red;
      }
      li {
        cursor: pointer;
        width: 10px;
        height: 10px;
        // background: white;
		background: #333e50;
        border-radius: 50%;
		margin-top: 0px;
      }
    }
  }
}
</style>
