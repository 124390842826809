<template>
	<div class="first">
		<ul v-for="item in items" :key="item.path" class="firstMenu">
			<li>
				<router-link :to="item.path" @click.prevent="handleMenuClick(item)" >{{ item.name }}</router-link>
				<!-- 递归渲染子菜单 -->
				<div class="child">
					<MultiLevelMenu v-if="item.children" :items="item.children" />
				</div>
			</li>
		</ul>
		<!-- 对话框 -->
		<dialog ref="dialog">
			<p>当前未选择项目，请先选择项目再进行此操作！</p>
			<button @click="closeDialog">确定</button>
		</dialog>
	</div>
</template>

<script>
	export default {
		name: 'MultiLevelMenu',
		props: {
			items: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				selectedItem: null, // 用于存储当前选择的菜单项
			};
		},
		methods: {
			handleMenuClick(item) {
				if (item.requiresDialog) {
					this.selectedItem = item;
					this.openDialog();
				} else {
					this.$router.push({path: '/mainHome/root'});
				}
			},
			openDialog() {
				this.$refs.dialog.showModal();
			},
			closeDialog() {
				this.$refs.dialog.close();
				this.$router.push({path: '/mainHome/root'});
			},
			// confirmAction() {
			// 	if (this.selectedItem) {
			// 		this.$router.push({path: '/mainHome/root'});
			// 		this.closeDialog();
			// 	}
			// },
		},
	};
</script>

<style scoped>
	.first {
		min-width: 150px; /* 设置最小宽度 */
		height: auto;
		width: auto;
	}
	.firstMenu ul {
		list-style: none;
		padding-left: 20px;
		border-radius: 10px;
		text-decoration: none;
	}

	.firstMenu ul li {
		padding: 5px 0;
	}
	
	.firstMenu ul li a {
		text-decoration: none;
		color: #333;
		cursor: pointer;
		font-size: 18px;
	}
	
	.child ul:hover {
		background-color: #00009e;
	}
	.child li a {
		text-decoration: none;
		color: #333;
		cursor: pointer;
		font-size: 18px;
	}
	.child ul:hover a {
		color: #fff;
	}
	li a {
		text-decoration: none;
	}
	li a:link {
		text-decoration: none;
	}
	li a:visited {
		text-decoration: none;
	}

/* 	router-link {
		text-decoration: none;
		color: #333;
		font-size: 24px;
	}

	router-link a:hover {
		color: #42b983 !important;
	} */
	
	/* 对话框样式 */
	dialog {
		height: 140px;
		border: none;
		padding: 20px;
		border-radius: 8px;
		background-color: #fff;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	}

	dialog::backdrop {
		background-color: rgba(0, 0, 0, 0.3);
	}

	button {
		margin-top: 20px;
		margin-left: 40%;
		padding: 10px 20px;
		border: none;
		background-color: #007bff;
		color: #fff;
		border-radius: 4px;
		cursor: pointer;
		font-size: 20px;
		float: left;
	}

	button:hover {
		background-color: #0056b3;
	}
	
</style>