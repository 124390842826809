import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/authStore'; // 导入认证存储

// 引入Vue组件
// import App from '@/App.vue';
import MainHomePage from '@/components/MainHome.vue';
import Home from '@/components/Home.vue';
import RootPage from '../views/RootPage.vue';
import NotionMenu1 from '../views/NotionMenu1.vue';
import NotionMenu2 from '../views/NotionMenu2.vue';
import NotionMenu3 from '../views/NotionMenu3.vue';
import NotionMenu4 from '../views/NotionMenu4.vue';
import LogicMenu1 from '../views/LogicMenu1.vue';
import LogicMenu2 from '../views/LogicMenu2.vue';
import LogicMenu3 from '../views/LogicMenu3.vue';
import LogicMenu4 from '../views/LogicMenu4.vue';
import LogicMenu5 from '../views/LogicMenu5.vue';
import PhysicalMenu1 from '../views/PhysicalMenu1.vue';
import PhysicalMenu2 from '../views/PhysicalMenu2.vue';
import PhysicalMenu3 from '../views/PhysicalMenu3.vue';
import PhysicalMenu4 from '../views/PhysicalMenu4.vue';
import PhysicalMenu5 from '../views/PhysicalMenu5.vue';
import PhysicalMenu6 from '../views/PhysicalMenu6.vue';
import PhysicalMenu7 from '../views/PhysicalMenu7.vue';
import SystemMenu1 from '../views/SystemMenu1.vue';
import SystemMenu2 from '../views/SystemMenu2.vue';
import SystemMenu3 from '../views/SystemMenu3.vue';
import SystemMenu4 from '../views/SystemMenu4.vue';

// 定义路由
const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	// {
	// 	path: '/home',
	// 	name: 'Home',
	// 	component: Home,
	// },
	{
		path: '/mainHome',
		name: 'MainHome',
		component: MainHomePage,
		meta: { requiresAuth: true }, // 添加 meta 字段来指明需要认证 
		children: [
			{
				path: '/mainHome/root',
				name: 'RootPage',
				component: RootPage,
			},
			{
				path: '/notion',
				name: 'Notion',
				component: NotionMenu1,
			},
			{
				path: '/notion/menu1_1',
				name: 'NotionMenu1',
				component: NotionMenu1,
			},
			{
				path: '/notion/menu1_2',
				name: 'NotionMenu2',
				component: NotionMenu2,
			},
			{
				path: '/notion/menu1_3',
				name: 'NotionMenu3',
				component: NotionMenu3,
			},
			{
				path: '/notion/menu1_4',
				name: 'NotionMenu4',
				component: NotionMenu4,
			},
			{
				path: '/logic',
				name: 'Logic',
				component: LogicMenu1,
			},
			{
				path: '/logic/menu2_1',
				name: 'LogicMenu1',
				component: LogicMenu1,
			},
			{
				path: '/logic/menu2_2',
				name: 'LogicMenu2',
				component: LogicMenu2,
			},
			{
				path: '/logic/menu2_3',
				name: 'LogicMenu3',
				component: LogicMenu3,
			},
			{
				path: '/logic/menu2_4',
				name: 'LogicMenu4',
				component: LogicMenu4,
			},
			{
				path: '/logic/menu2_5',
				name: 'LogicMenu5',
				component: LogicMenu5,
			},
			{
				path: '/physical',
				name: 'Physical',
				component: PhysicalMenu1,
			},
			{
				path: '/physical/menu3_1',
				name: 'PhysicalMenu1',
				component: PhysicalMenu1,
			},
			{
				path: '/physical/menu3_2',
				name: 'PhysicalMenu2',
				component: PhysicalMenu2,
			},
			{
				path: '/physical/menu3_3',
				name: 'PhysicalMenu3',
				component: PhysicalMenu3,
			},
			{
				path: '/physical/menu3_4',
				name: 'PhysicalMenu4',
				component: PhysicalMenu4,
			},
			{
				path: '/physical/menu3_5',
				name: 'PhysicalMenu5',
				component: PhysicalMenu5,
			},
			{
				path: '/physical/menu3_6',
				name: 'PhysicalMenu6',
				component: PhysicalMenu6,
			},
			{
				path: '/physical/menu3_7',
				name: 'PhysicalMenu7',
				component: PhysicalMenu7,
			},
			{
				path: '/system',
				name: 'System',
				component: SystemMenu1,
			},
			{
				path: '/system/menu4_1',
				name: 'SystemMenu1',
				component: SystemMenu1,
			},
			{
				path: '/system/menu4_2',
				name: 'SystemMenu2',
				component: SystemMenu2,
			},
			{
				path: '/system/menu4_3',
				name: 'SystemMenu3',
				component: SystemMenu3,
			},
			{
				path: '/system/menu4_4',
				name: 'SystemMenu4',
				component: SystemMenu4,
			},
		],
	},
	
];

// 创建router实例
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

// 添加全局的 beforeEach 守卫
router.beforeEach((to, from, next) => {
	const authStore = useAuthStore();
	
	// 检查路由是否需要认证
	console.log(authStore);
	if (to.matched.some(record => record.meta.requiresAuth)) {
		console.log('Login error!'+authStore.token);
		// 如果未认证，重定向到首页
		if (!authStore.isAuthenticated) {
			console.log('Login error!'+authStore.token);
			next({ path: '/' });
		} else {
			next(); // 允许进入路由
		}
	} else {
		next(); // 允许进入路由
	}
});

export default router;