<template>
<div class="mainBottom">
    <ul>
        <p class="line_h">山海慧光科技(北京)有限公司 版权所有 </p>
    </ul>
</div>
</template>
<script>
export default {
  name: 'mainBottom',
  data() {
    return {}
  },
  methods: {
  }
}
</script>
<style scoped>
.mainBottom {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    margin-top: 0px;
    text-align: center;
    border-top: 1px solid #555555;
    line-height: 18px;
    color: rgb(85, 85, 85);
    margin-top: 0px;
    margin-bottom: 60px;
    padding-bottom: 10px;
    padding-top: 8px;
}
.mainBottom a {
    color: rgb(85, 85, 85);
}
</style>