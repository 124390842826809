import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
	state: () => ({
		token: localStorage.getItem('jwt') || '', // 初始化 token
		username: localStorage.getItem('username') || '', // 从 localStorage 初始化用户名
	}),
	actions: {
		setToken(token, username) {
			this.token = token;
			this.username = username;
			localStorage.setItem('jwt', token);
			localStorage.setItem('username', username);
		},
		clearToken() {
			this.token = '';
			this.username = '';
			localStorage.removeItem('jwt');
			localStorage.removeItem('username');
		},
	},
	getters: {
		isAuthenticated: (state) => !!state.token, // 判断用户是否已认证
	},
});
