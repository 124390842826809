<template>
	<Header></Header>
	<div class="mainHome">
		<aside>
			<MultiLevelMenu :items="menuData" />
		</aside>
		<main>
			<router-view />
		</main>
	</div>
	<Bottom></Bottom>
</template>

<script>
	import Header from '@/components/BodyHeader.vue';
	import Bottom from '@/components/MainBottom.vue';
	import MultiLevelMenu from '@/components/MultiLevelMenu.vue';
	import { menuData } from '@/data/menu';
	
	export default {
		name: 'MainHome',
		components: {
			Header,
			Bottom,
			MultiLevelMenu,
		},
		data() {
			return {
				menuData,
			}
		},
	};
</script>

<style scoped>
	.mainHome {
		display: flex;
		height: 100%;
		width: 100%;
	}
	aside {
		width: 180px;
		background-color: #f5f5f5;
		padding: 20px;
		box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
	}

	main {
		width: 100%;
		flex-grow: 1;
		padding: 20px;
		background-color: #ffffff;
		overflow-x: hidden; 
		overflow-y: hidden; 
	}
	
</style>