export const menuData = [
	{
		name: '开始建模',
		path: '/mainHome/root',
		requiresDialog: false,
	},
	{
		name: '概念模型',
		path: '/notion',
		children: [
			{
				name: '用户访谈',
				path: '/notion/menu1_1',
				requiresDialog: true,
			},
			{
				name: '调研问卷',
				path: '/notion/menu1_2',
				requiresDialog: true,
			},
			{
				name: '需求整理',
				path: '/notion/menu1_3',
				requiresDialog: true,
			},
			{
				name: '调研报告',
				path: '/notion/menu1_4',
				requiresDialog: true,
			},
		],
	},
	{
		name: '逻辑模型',
		path: '/logic',
		children: [
			{
				name: '需求分析',
				path: '/logic/menu2_1',
				requiresDialog: true,
			},
			{
				name: '业务流程',
				path: '/logic/menu2_2',
				requiresDialog: true,
			},
			{
				name: '数据流程',
				path: '/logic/menu2_3',
				requiresDialog: true,
			},
			{
				name: '模型创建',
				path: '/logic/menu2_4',
				requiresDialog: true,
			},
			{
				name: '模型优化',
				path: '/logic/menu2_5',
				requiresDialog: true,
			},
		],
	},
	{
		name: '物理模型',
		path: '/physical',
		children: [
			{
				name: '数据库管理',
				path: '/physical/menu3_1',
				requiresDialog: true,
			},
			{
				name: '物理模型优化',
				path: '/physical/menu3_2',
				requiresDialog: true,
			},
			{
				name: '生成物理模型',
				path: '/physical/menu3_3',
				requiresDialog: true,
			},
			{
				name: '创建数据库',
				path: '/physical/menu3_4',
				requiresDialog: true,
			},
			{
				name: '数据库迁移',
				path: '/physical/menu3_5',
				requiresDialog: true,
			},
			{
				name: '数据库优化',
				path: '/physical/menu3_6',
				requiresDialog: true,
			},
			{
				name: '数据治理',
				path: '/physical/menu3_7',
				requiresDialog: true,
			},
		],
	},
	// {
	// 	name: '系统管理',
	// 	path: '/system',
	// 	children: [
	// 		{
	// 			name: '参数管理',
	// 			path: '/system/menu4_1',
	// 		},
	// 		{
	// 			name: '续费管理',
	// 			path: '/system/menu4_2',
	// 		},
	// 		{
	// 			name: '个人中心',
	// 			path: '/system/menu4_3',
	// 		},
	// 		{
	// 			name: '退出登录',
	// 			path: '/',
	// 			requiresDialog: true,
	// 		},
	// 	],
	// },
];
