<template>
    <div class="bigData">
		<div class="mainTitle">
			<p>建模工具</p>
		</div>
        <div class="productBox">
            <div class="productTitle">
                <p>概念建模工具</p>
            </div>
            <div class="productContent">
				<img src="@/assets/images/product1.jpg" />
                <p>概念建模工具是面向数据库用户的现实世界的模型，主要用来描述世界的概念化结构，它使数据库的设计人员在设计的初始阶段，摆脱计算机系统及DBMS的具体技术问题，集中精力分析数据以及数据之间的联系。</p>
				<p>山海云提供的概念建模工具涵盖了概念建模各个阶段的需求，包括需求交流工具，需求分析及整理工具，概念模型生成工具等。</p>
            </div>
        </div>
        <div class="productBox">
            <div class="productTitle">
                <p>逻辑建模工具</p>
            </div>
            <div class="productContent">
				<img src="@/assets/images/product2.jpg" />
                <p>逻辑建模工具主要帮助用户借助相对抽象、逻辑统一且结构稳健的结构，实现数据仓库系统所要求的数据存储目标，支持大量的分析应用，是数据管理分析的工具和交流的有效手段。</p>
				<p>山海云提供的逻辑建模工具包括了常用数据模型的建模工具。提供了细化工具、约束工具、效率分析与逻辑检查以及各类优化工具服务。</p>
            </div>
        </div>
        <div class="productBox">
            <div class="productTitle">
                <p>物理建模工具</p>
            </div>
            <div class="productContent">
				<img src="@/assets/images/product3.jpg" />
                <p>物理建模工具基于各类数据库存储及管理特点，结合用户逻辑模型为用户提供数据库物理模型的构建，包括数据库的存储过程、操作、触发、视图和索引表等。</p>
				<p>山海云提供的物理数据模型建模工具可以根据逻辑模型自动生成数据库结构，实现物理设计结果的数据库间移植，可以定制生成模型报告。</p>
            </div>
        </div>
    </div>

</template>
<script>
export default {
  name: 'bigData',
  data() {
    return {}
  },
  methods: {
  }
}
</script>
<style scoped>
.bigData {
    width: 100%;
    /* height: 500px; */
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    /* border-top: 1px solid #555555; */
    line-height: 18px;
    color: rgb(85, 85, 85);
    padding-bottom: 10px;
    padding-top: 8px;
}
.mainTitle {
	width: 100%;
	margin: 0 auto;
	margin-top: 10px;
}
.mainTitle > p {
	text-align: center;
	font-size: 40px;
	font-family: 微软雅黑;
	font-weight: 500;
	color: #19191a;
}
.productTitle {
	width: 100%;
	margin: 0 auto;
	margin-top: 10px;
}
.productTitle > p {
	text-align: left;
	font-size: 32px;
	font-family: 微软雅黑;
	font-weight: 500;
	color: #19191a;
    margin-left: 10%;
}
.productContent {
	width: 80%;
	height:300px;
	margin: 0 auto;
	margin-top: 10px;
	border-top: 3px solid #3d29f6;
}
.productContent > p {
	min-width: 770px; /* 设置最小宽度 */
	min-height: 100px; /* 设置最小高度 */
	width: auto;
	height: auto;
	text-align: left;
	text-indent: 2em;
	font-size: 24px;
	font-family: 微软雅黑;
	font-weight: 400;
	line-height: 40px;
	color: #19191a;
    margin-top: 30px;
    margin-left: 440px;
}
.productContent img {
	width: 427px;
	height: 240px;
	margin-top: 30px;
	margin-left: 0%;
	float: left;
}

/* .desc_box {
    box-sizing: border-box;
    font-family: '微软雅黑';
}
.desc_box > div:nth-child(2) p {
  font-size: 16px;
  color: #666;
  font-weight: 300;
  width: 917px;
  margin: 0 auto;
  line-height: 28px;
  text-align: center;
  padding-top: 34px;
}
.main_tit {
  width: 150px;
  margin: 0 auto;
    margin-top: 0px;
  margin-top: 142px;
}
.place_bot {
  padding-bottom: 97px;
  width: 935px;
  margin: 0 auto;
}
.place_bot > div {
  width: 935px;
  height: 173px;
}
.place_bot > div dl {
  float: left;
  margin-left: 175px;
}
img, embed, object, audio, video {
  height: auto;
  max-width: 100%;
}
img {
  border: none;
} */
</style>