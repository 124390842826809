<template>
	<div class="mainHeader">
		<ul>
			<img src="@/assets/images/shanhailogo.jpg" />
			<div class="menus">
				<li>
					<a @click="showLogin">用户登录</a>
				</li>
			</div>
		</ul>
	</div>
	<div class="login" v-if="isShow">
		<div class="content">
			<h3>用户登录</h3>		
			<el-form :model="loginForm" @submit.prevent="handleLogin">
				<el-form-item label="用户:">
					<el-input v-model="loginForm.username" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="密码:">
					<el-input type="password" v-model="loginForm.password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleLogin">登录</el-button>
					<el-button type="primary" @click="isShow = false;">取消</el-button>
				</el-form-item>
			</el-form>

<!-- 			<div class="inputs">
				<input type="text" v-model="username" placeholder="您的账户名" />
				<input type="password" v-model="password" placeholder="******" />
			</div>
			<div class="btns">
				<button @click="login">登陆</button>
				<button @click="isShow = false">取消</button>
			</div> -->
		</div>
	</div>
</template>

<script>
	import { reactive } from 'vue'; 
	import { useAuthStore } from '@/stores/authStore.js';
	import { useRouter } from 'vue-router';
	import { login } from '@/services/authService'; // 导入自定义 API 服务
	import { ElMessage } from 'element-plus'; 

	
	export default {
		setup() {
			const authStore = useAuthStore();
			const router = useRouter();
			const loginForm = reactive({
				username: '',
				password: '',
			});

			const handleLogin = async () => {
				try {
					const response = await login(loginForm);
					if (response.success) {
						const username = loginForm.username;
						// const response = await axios.post('/login', this.loginForm);
						authStore.setToken(response.data.token,username); // 设置 token
						ElMessage.success('登录成功! 当前用户: '+username);
						router.push({ path: '/mainHome/root' });
					} else {
						ElMessage.success('登录失败: '+response.msg);
					}
				} catch (error) {
					// this.$message.error('Login failed: ' );
					ElMessage.error('登录失败！ ' + (error.response?.data?.message || error.message)); // Display error message
				}
			};
			

			return {
				loginForm,
				handleLogin,
			};
		},
		name: 'mainHeader',
		data() {
			return {
				isShow: false,
				// username: '',
				// password: '',
			};
		},
		methods: {
			showLogin() {
				this.isShow = true;
			},
			// login() {
			// 	console.log(this.username);
			// 	console.log(this.password);
			// 	if (this.username === "kaipuyun" && this.password === "123456" ) {
			// 		console.log("scuess!");
			// 	} else {
			// 		alert("登录失败，请确认您的用户名和密码！");
			// 	}
			// }
		},
	};
</script>

<style scoped>
	.mainHeader {
		overflow-y: hidden;
		overflow-x: hidden;
		box-sizing: border-box;
		width: 100%;
		height: 100px;
		margin: 0px 5px 0px 0px;
		padding-right: 10px;
		background-color: #333e50;
		position: relative;
	}
	.menus {
		width: 150px;
		height: 80px;
		float: right;
		color: #fff;
		background-color: #333e50;
	}
	.menus a {
		font-size: 18px;
		cursor: pointer;
	}
	.menus a:hover {
		color: #fea900;
	}
	.mainHeader img {
		width: 200px;
		height: 60px;
		margin-top: 10px;
		margin-left: 20px;
		float: left;
	}
	.mainHeader a {
		text-decoration: none;
		display: block;
		height: 40px;
		line-height: 40px;
		border-width: 1px 1px 0 0;
		background: #333e50;
		margin-top: 30px;
	}
	
	.login {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 999;
	}
	
	.content {
		width: 400px;
		height: 280px;
		background-color: white;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.content h3{
		font-size: 32px;
		margin-top: 0px;
		margin-bottom: 10px;
		font-family: "microsoft yahei", "微软雅黑";
		font-weight: normal;
	}

	.el-form {
		max-width: 300px;
		margin: 0 auto;
		padding-top: 10px;
	}

/* 
ul{
  list-style-type: none;
  overflow: hidden;
  position: absolute; 
  top: 0px;
  width: 100%;
  height: 80px;
  margin: 0px 0px 0px 0px;
  background-color: #333e50;
}  
li a {
  width: 60%;
  list-style-type: none;
  float: left;
  text-align: center;
  position: relative;
  color: #fff;
}
li a:hover {
  color: #ffb100;
  background-color: #333e50;
}

.inputs {
  width: 300px;
  height: 110px;
}

.inputs input {
	width: 100%;
	height: 30px;
	margin: 10px 0 0 0;
	font-size: 24px;
	float: left;
}

.btns {
  width: 300px;
  height: 40px;
  float: left;
}
.btns button {
  height: 30px;
  width: 35%;
  float: left;
  margin-left: 20px;
} */
</style>
