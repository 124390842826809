<template>
	<div class="mainHeader">
		<ul>
			<img src="@/assets/images/shanhailogo.jpg" />
			<div class="menus">
				<li>
					<a @click="logOut">退出登录</a>
				</li>
			</div>
		</ul>
	</div>
</template>

<script>
	import { useAuthStore } from '@/stores/authStore.js';
	import { useRouter } from 'vue-router';
	
	export default {
		name: 'mainHeader',
		setup() {
			const authStore = useAuthStore();
			const router = useRouter();

			// 退出登录
			const logOut = () => {
				console.log('Login error!'+authStore.token);
				authStore.clearToken(); // 清除用户信息
				router.push({ path: '/' });// 重定向到首页
			};
			
			return {
				logOut,
			};
		},
	};
</script>

<style scoped>
	.mainHeader {
		overflow-y: hidden;
		overflow-x: hidden;
		box-sizing: border-box;
		width: 100%;
		height: 100px;
		margin: 0px 5px 0px 0px;
		padding-right: 10px;
		background-color: #333e50;
		position: relative;
	}
	.menus {
		width: 150px;
		height: 80px;
		float: right;
		color: #fff;
		background-color: #333e50;
	}
	.menus a {
		font-size: 18px;
		cursor: pointer;
	}
	.menus a:hover {
		color: #fea900;
	}
	.mainHeader img {
		width: 200px;
		height: 60px;
		margin-top: 10px;
		margin-left: 20px;
		float: left;
	}
	.mainHeader a {
		text-decoration: none;
		display: block;
		height: 40px;
		line-height: 40px;
		border-width: 1px 1px 0 0;
		background: #333e50;
		margin-top: 30px;
	}

	.content {
		width: 400px;
		height: 280px;
		background-color: white;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.content h3{
		font-size: 32px;
		margin-top: 0px;
		margin-bottom: 10px;
		font-family: "microsoft yahei", "微软雅黑";
		font-weight: normal;
	}

	.el-form {
		max-width: 300px;
		margin: 0 auto;
		padding-top: 100px;
	}

/* 
ul{
  list-style-type: none;
  overflow: hidden;
  position: absolute; 
  top: 0px;
  width: 100%;
  height: 80px;
  margin: 0px 0px 0px 0px;
  background-color: #333e50;
}  
li a {
  width: 60%;
  list-style-type: none;
  float: left;
  text-align: center;
  position: relative;
  color: #fff;
}
li a:hover {
  color: #ffb100;
  background-color: #333e50;
}

.login {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}

.inputs {
  width: 300px;
  height: 110px;
}

.inputs input {
	width: 100%;
	height: 30px;
	margin: 10px 0 0 0;
	font-size: 24px;
	float: left;
}

.btns {
  width: 300px;
  height: 40px;
  float: left;
}
.btns button {
  height: 30px;
  width: 35%;
  float: left;
  margin-left: 20px;
} */
</style>
