import axios from '@/utils/request.ts';  // 导入封装的 Axios 实例

export const login = (credentials) => {
	return axios.post('/auth/login', credentials);
};

export const logout = () => {
	return axios.post('/logout');
};

export const updatePassword = (newPassword) => {
	return axios.post('/sys/updatePassword', newPassword);
};